<template>
  <section class="section keep-footer-off-page">
    <div class="container">
      <div class="columns is-centered">
        <!-- Card -->
        <div class="column is-two-thirds box p-5">
          <!-- Main Content -->
          <h1 class="mb-5 heading has-text-centered">Active Projects</h1>

          <!-- Loading Spacer -->
          <b-loading
            class="loadable"
            v-if="dataIsLoading"
            :active="true"
            :is-full-page="false"
          ></b-loading>

          <!-- SmartTag -->
          <div v-else>
            <b-collapse
              class="m-2 ml-5"
              :open="true"
              aria-id="contentIdForA11y1"
              v-for="(project, pid) in projects"
              :key="pid"
              ><b-taglist
                attached
                slot="trigger"
                aria-controls="contentIdForA11y1"
              >
                <!-- Project/Role tags -->
                <SmartTag
                  @click.native="navigateToProject(pid)"
                  class="my-1"
                  :tags="[
                    { type: 'is-project', value: project.projectName },
                    {
                      type: 'is-role',
                      value: project.roles[uid],
                    },
                  ]"
                />
              </b-taglist>
              <!-- Task tags -->
              <div
                class="pl-5 my-1"
                v-for="(task, tid) in project.tasks"
                :key="tid"
              >
                <SmartTag
                  @click.native="navigateToTask(tid)"
                  class="is-clickable"
                  size="is-small"
                  :tags="[
                    { type: 'is-task', value: task.name },
                    { type: 'is-date', value: task.deadlineDate },
                    { type: 'is-skill', value: task.skills[0] },
                  ]"
                /></div
            ></b-collapse>
          </div>

          <!-- Message for no Projects -->
          <b-message
            v-if="!hasProjects"
            type="is-info"
            aria-close-label="Close message"
          >
            <p>
              You do not have any projects yet. Join an existing project or
              start your own by clicking below.
            </p>
          </b-message>

          <!-- Error Messages -->
          <b-message
            v-if="errorInGetRequest"
            type="is-danger"
            aria-close-label="Close message"
          >
            <p>Oops something has gone wrong. Please try again later.</p>
          </b-message>
        </div>
      </div>
      <div class="buttons is-centered mt-6">
        <button
          class="button is-info"
          @click="$router.push({ name: 'Join_project' })"
          @keyup.enter="$router.push({ name: 'Join_project' })"
        >
          Join a Project
        </button>
        <button
          class="button is-info"
          @click="$router.push({ name: 'New_project' })"
          @keyup.enter="$router.push({ name: 'New_project' })"
        >
          Start a Project
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import SmartTag from "@/components/SmartTag.vue";
import axiosRetry from "axios-retry";
import isEmpty from "lodash/isEmpty";
import store from "../store";

export default {
  name: "projects-view",

  components: {
    SmartTag,
  },
  data() {
    return {
      uid: "",
      projects: "",
      dataIsLoading: true,
      hasProjects: true,
      errorInGetRequest: false,
    };
  },
  methods: {
    startLoadingAnimations() {
      this.dataIsLoading = true;
    },
    stopLoadingAnimations() {
      this.dataIsLoading = false;
    },
    navigateToTask(tid) {
      this.$router.push({ name: "Task", params: { tid: tid } });
    },
    navigateToProject(pid) {
      this.$router.push({ name: "Project", params: { pid: pid } });
    },
    async getUserProjects() {
      const idToken = store.state.auth.user.accessToken;
      let config = {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };
      const baseUrl = "https://solute-python3.onrender.com";
      const subdomain = "/user/projects?uid=";
      const uid = store.state.auth.user.uid;
      const fullUrl = baseUrl + subdomain + uid;

      try {
        const response = await this.$axios.get(fullUrl, config);
        this.projects = response.data;

        // Set no projects info flag.
        if (isEmpty(this.projects)) {
          this.hasProjects = false;
        }

        console.log("Projects Data");
        console.log(this.projects);
      } catch (error) {
        this.errorInGetRequest = true;
        if (error.response) {
          // Request made but the server responded with an error
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          this.$buefy.toast.open({
            message: "Server Error",
            type: "is-danger",
          });
        } else if (error.request) {
          // Request made but no response is received from the server.
          console.log(error.request);
          this.$buefy.toast.open({
            message: "Error : No Response",
            type: "is-danger",
          });
        } else {
          // Error occured while setting up the request
          console.log("Error", error.message);
          this.$buefy.toast.open({
            message: "Request Error",
            type: "is-danger",
          });
        }
      }
      // Change loading state.
      this.stopLoadingAnimations();
    },
  },
  created() {
    // Set Exponential back-off retry delay between requests
    axiosRetry(this.$axios, { retryDelay: axiosRetry.exponentialDelay });
    this.uid = store.state.auth.user.uid;
    this.startLoadingAnimations();
    this.getUserProjects();
  },
};
</script>

<style scoped>
.loadable {
  position: relative;
  height: 3rem;
}
</style>
